/*  SMARTPHONE PORTRAIT QUERY*/
@media (min-width: 300px) and (max-width: 768px) {
    .parallax2 {
        position: relative;
        height: 150vw;
        overflow: hidden;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
    }
    .videodestaque {
        grid-column: 1/4;
        grid-row: 1/3;
        object-fit: cover;
        min-width: 100%;
        min-height: 100%;
        height: 30vw;
        z-index: -1;
        filter: brightness(0.7);
    }
    .textoParallax {
        margin-top: -10px;
        grid-column: 2;
        grid-row: 1;
        z-index: 1;
    }
    .textoParallax h2 {
        text-transform: uppercase;
        font-family: 'Poppins', sans-serif;
        font-size: 50px;
        font-weight: 800;
        color: white;
    }
    .textoParallax p {
        color: white;
        font-family: 'Poppins', sans-serif;
        font-size: 1.1em;
        margin: 0 0px;
        filter: drop-shadow( 1px 2px 3px rgb(24, 24, 24));
    }
}
/*  SMARTPHONE LANDSCAPE QUERY*/
@media (min-width: 768px) and (max-width: 1099px) {
  
}
/*  DESKTOP HALF HD QUERY*/
@media (min-width: 1100px) and (max-width: 1366px) {
    .parallax2 {
        position: relative;
        height: 70vw;
        overflow: hidden;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
    }
    .videodestaque {
        grid-column: 1/4;
        grid-row: 1/3;
        object-fit: cover;
        min-width: 100%;
        min-height: 100%;
        height: 30vw;
        z-index: -1;
        filter: brightness(0.7);
    }
    .textoParallax {
        margin-top: -30px;
        grid-column: 2;
        grid-row: 1;
        z-index: 1;
    }
    .textoParallax h2 {
        text-transform: uppercase;
        font-size: 50px;
        font-weight: 800;
        color: white;
    }
    .textoParallax p {
        color: white;
        font-size: 1.3em;
        margin: 0 -200px;
        filter: drop-shadow( 1px 2px 3px rgb(24, 24, 24));
    }
}
/*  DESKTOP FULL HD QUERY*/
@media (min-width: 1367px) and (max-width: 1920px) {
    .parallax2 {
        position: relative;
        height: 115vh;
        overflow: hidden;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
    }
    .videodestaque {
        grid-column: 1/4;
        grid-row: 1/3;
        object-fit: cover;
        min-width: 100%;
        min-height: 100%;
        height: 30vw;
        z-index: -1;
        filter: brightness(0.7);
    }
    .textoParallax {
        margin-top: -30px;
        grid-column: 2;
        grid-row: 1;
        z-index: 1;
    }
    .textoParallax h2 {
        text-transform: uppercase;
        font-family: 'Poppins', sans-serif;
        font-size: 70px;
        font-weight: 800;
        color: white;
    }
    .textoParallax p {
        color: white;
        font-family: 'Poppins', sans-serif;
        font-size: 26px;
        margin: 0 -200px;
        margin-top: -35px;
        filter: drop-shadow( 3px 3px 1px rgb(24, 24, 24));
    }
}