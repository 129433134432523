/*  SMARTPHONE PORTRAIT QUERY*/
@media (min-width: 300px) and (max-width: 768px) {
  body, html {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
  
  .parallax {
    position: relative;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
  
  .videobg {
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0%;
    width: 100%;
    height: 230vw;
    object-fit: cover;
    filter: brightness(0.7);
    z-index: -1;
  }
  
  main {
    color: white;
    height: 60vw;
  }
  
  main h1 {
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    font-weight: 800;
    -webkit-text-stroke-width: 0.7px;
    letter-spacing: 7px;
    color: rgba(0, 0, 0, 0);
    filter: drop-shadow(10px 10px 3px black);
    animation: piscar 15s infinite;
    padding: 20px;
    font-size: 60px;
    margin-top: 140px;
    margin-bottom: 50px;
    z-index: 1;
  }

  @keyframes piscar {
    0% {
      color: rgba(51, 51, 51, 0.433);
      -webkit-text-stroke-color: #000000;
    }
    50% {
      color: rgba(255, 255, 255, 0.877);
      -webkit-text-stroke-color: #000000e9;
    }
    100% {
      color: rgba(0, 0, 0, 0);
      -webkit-text-stroke-color: #00000000;
    }
  }
  .content {
    margin: 0px -150px;
  }
  
  main p {
    font-family: 'Poppins', sans-serif;
    font-weight: 100;
    font-size: 22px;
    margin-top: -60px;
    padding: 60px 100px;
    z-index: 1;
    text-transform: uppercase;
  }

  .word-fade {
    display: inline-block;
    transition: opacity 0.5s ease-in-out;
  }
  
  .word-hidden {
    opacity: 0;
  }
  
  .word-visible {
    opacity: 1;
  }

  #clique {
    font-family: 'Poppins', sans-serif;
    font-weight: 100;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 1.2em;
    color: white;
    margin-top: 20px;
    width: 330px;
    height: 55px;
    background-color: rgba(0, 0, 0, 0.500);
    cursor: pointer;
    display: inline-block;
    border-radius: 20px;
    border-width: 1px;
    border-color: blue;
    transition: 0.5s all;
    transform: 1s ease;
  }
  #clique:hover {
    transform: translateY(-5px);
    border-color: purple;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.534);
    background-color: rgba(0, 0, 0, 0.893);
  }
}
/*  SMARTPHONE LANDSCAPE QUERY*/
@media (min-width: 768px) and (max-width: 1099px) {
  
}
/*  DESKTOP HALF HD QUERY*/
@media (min-width: 1100px) and (max-width: 1366px) {
  body, html {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
  
  .parallax {
    position: relative;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
  
  .videobg {
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(0.7);
    z-index: -1;
  }
  
  main {
    color: white;
    height: 17vw;
  }
  
  main h1 {
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    font-weight: 800;
    letter-spacing: 7px;
    color: rgba(0, 0, 0, 0);
    filter: drop-shadow(10px 10px 1px black);
    animation: piscar 15s infinite;
    padding: 20px;
    font-size: 70px;
    margin-top: 154px;
    margin-bottom: 50px;
    z-index: 1;
  }

  @keyframes piscar {
    0% {
      color: rgba(51, 51, 51, 0.433);
      -webkit-text-stroke-color: #000000;
    }
    50% {
      color: rgba(255, 255, 255, 0.877);
      -webkit-text-stroke-color: #000000e9;
    }
    100% {
      color: rgba(0, 0, 0, 0);
      -webkit-text-stroke-color: #00000000;
    }
  }
  .content {
    margin: 0px -150px;
  }
  
  main p {
    font-family: 'Segoe UI', sans-serif;
    font-weight: 100;
    font-size: 28px;
    margin-top: 0px;
    margin-bottom: 80px;
    padding: 20px -100px;
    z-index: 1;
    text-transform: uppercase;
  }

  .word-fade {
    display: inline-block;
    transition: opacity 0.5s ease-in-out;
  }
  
  .word-hidden {
    opacity: 0;
  }
  
  .word-visible {
    opacity: 1;
  }

  #clique {
    font-family: 'Poppins', sans-serif;
    font-weight: 100;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 1.2em;
    color: white;
    margin-top: 20px;
    width: 330px;
    height: 55px;
    background-color: rgba(0, 0, 0, 0.500);
    cursor: pointer;
    display: inline-block;
    border-radius: 20px;
    border-width: 1px;
    border-color: blue;
    transition: 0.5s all;
    transform: 1s ease;
  }
  #clique:hover {
    transform: translateY(-5px);
    border-color: purple;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.534);
    background-color: rgba(0, 0, 0, 0.893);
  }
}

@media (min-width: 1367px) and (max-width: 1920px) {
  body, html {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
  
  .parallax {
    position: relative;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
  
  .videobg {
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(0.7);
    z-index: -1;
  }
  
  main {
    color: white;
    height: 12vw;
  }
  
  main h1 {
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    font-weight: 100;
    -webkit-text-stroke-width: 0.1px;
    letter-spacing: 7px;
    color: rgba(0, 0, 0, 0);
    filter: drop-shadow(10px 10px 1px black);
    animation: piscar 15s infinite;
    padding: 20px;
    font-size: 90px;
    margin-top: 240px;
    margin-bottom: 100px;
    z-index: 1;
  }

  @keyframes piscar {
    0% {
      color: rgba(51, 51, 51, 0.433);
      -webkit-text-stroke-color: #000000;
    }
    50% {
      color: rgba(255, 255, 255, 0.877);
      -webkit-text-stroke-color: #000000e9;
    }
    100% {
      color: rgba(0, 0, 0, 0);
      -webkit-text-stroke-color: #00000000;
    }
  }
  .content {
    margin: 0px -150px;
  }
  
  main p {
    font-family: 'Segoe UI', sans-serif;
    font-weight: 100;
    font-size: 35px;
    margin-top: -60px;
    padding: 20px -100px;
    z-index: 1;
    text-transform: uppercase;
  }

  .word-fade {
    display: inline-block;
    transition: opacity 0.5s ease-in-out;
  }
  
  .word-hidden {
    opacity: 0;
  }
  
  .word-visible {
    opacity: 1;
  }

  #clique {
    font-family: 'Poppins', sans-serif;
    font-weight: 100;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 1.2em;
    color: white;
    margin-top: 20px;
    width: 330px;
    height: 55px;
    background-color: rgba(0, 0, 0, 0.500);
    cursor: pointer;
    display: inline-block;
    border-radius: 20px;
    border-width: 1px;
    border-color: blue;
    transition: 0.5s all;
    transform: 1s ease;
  }
  #clique:hover {
    transform: translateY(-5px);
    border-color: purple;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.534);
    background-color: rgba(0, 0, 0, 0.893);
  }
}