/*  SMARTPHONE PORTRAIT QUERY*/
@media (min-width: 300px) and (max-width: 768px) {
    .sobre {
        padding: 100px 50px;
        color: #fff;
        grid-column: 1/6;
        grid-row: 1/3;
        text-align: center;
    }
    .sobre h2 {
        font-family: 'Poppins', sans-serif;
        font-weight: 800;
        font-size: 3em;
        padding: 5px;
        margin-top: -30px;
        margin-bottom: 20px;
        text-transform: uppercase;
        text-shadow: 2px 2px 3px black;
    }
    .sobre p {
        font-family: 'Poppins', sans-serif;
        font-weight: 100;
        font-size: 1.23em;
    }
    .Clientes {
        display: flex;
        flex-direction: row;
        overflow: hidden;
        mask-image: linear-gradient(to right, transparent, black 20%, black 80%, transparent);
        -webkit-mask-image: linear-gradient(to right, transparent, black 20%, black 80%, transparent);
      }
      
      .Clientes-wrapper {
        animation: scroll 15s linear infinite;
        white-space: nowrap;
        opacity: 0.8;
        transition: 1s all;
      }
      
      @keyframes scroll {
        0% {
          transform: translateX(0);
        }
        100% {
          transform: translateX(-50%);
        }
      }
      
      .Clientes:hover .Clientes-wrapper {
        animation-play-state: paused;
        opacity: 1;
      }
      
      .Clientes img {
        height: 70px; /* Ajuste a altura conforme necessário */
        margin: -50px 10px;
        transition: filter 0.3s ease-in-out;
      }
      
    #logogirassol {
        height: 250px;
        width: 250px;
        filter: drop-shadow(-2px -2px 1px rgba(255, 166, 0, 0.572));
        transition: 0.5s all;
        transform: 1s ease-in-out;
    }
    #logogirassol:hover {
        transform: translateY(-5px);
    }
    #logoeduv {
        height: 250px;
        width: 250px;
        transition: 0.5s all;
        transform: 1s ease-in-out;
        filter: drop-shadow(1px 1px 0px rgb(231, 10, 10));
    }
    #logoeduv:hover {
        transform: translateY(-5px);
    }
    
    #logocrielacos {
        height: 250px;
        width: 250px;
        filter: drop-shadow(2px 2px 0px rgb(255, 154, 154));
        transition: 1s all;
        transform: 1s ease-in-out;
    }
    #logocrielacos:hover {
        transform: translateY(-5px);
    }
    
    #logomiguel {
        margin-bottom: 10px;
        height: 150px;
        width: 250px;
        filter: drop-shadow(-2px -2px 5px rgba(41, 125, 148, 1));
        transition: 0.5s all;
        transform: 1s ease-in-out;
    }
    #logomiguel:hover {
        transform: translateY(-5px);
    }
    #logosc {
        height: 250px;
        width: 250px;
        filter: drop-shadow(-2px -2px 3px rgb(187, 0, 131));
        transition: 0.5s all;
        transform: 1s ease-in-out;
    }
    #logosc:hover {
        transform: translateY(-5px);
    }
    #logopw {
        margin-bottom: 20px;
        height: 150px;
        width: 150px;
        filter: drop-shadow(-2px -2px 1px rgb(237, 19, 19));
        transition: 0.5s all;
        transform: 1s ease-in-out;
    }
    #logopw:hover {
        transform: translateY(-5px);
    }
    #logoupg {
        height: 250px;
        width: 250px;
        filter: drop-shadow(1px 1px 0px rgba(255, 255, 255, 0.939));
        transition: 0.5s all;
        transform: 1s ease-in-out;
    }
    #logoupg:hover {
        transform: translateY(-5px);
    }
    section {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: repeat(8, 1fr);
        background-color: rgb(0, 0, 0);
        margin: 0 auto;
        padding: 0px 0px 100px 0px;
        width: 100vw;
        height: 320vw;
        text-align: center;
    }
    
    #clique1 {
        font-family: 'Poppins', sans-serif;
        font-weight: 100;
        font-size: 1.1em;
        width: 300px;
        height: 45px;
        background-color: rgba(46, 46, 46, 1);
        color: white;
        border: 1px solid blue;
        cursor: pointer;
        display: inline-block;
        margin-right: 140px;
        margin-left: 7px;
        margin-bottom: -50px;
        border-radius: 30px;
        transition: 0.5s all;
        transform: 0.5s ease;
    }
    #clique1:hover {
        border: 1px solid purple;
        box-shadow: 0 10px 15px rgba(0, 0, 0, 0.534);
        background-color: rgb(0, 0, 0);
    }
}
/*  DESKTOP HALF HD QUERY*/
@media (min-width: 769px) and (max-width: 1367px) {
    .sobre {
        padding: 50px 215px;
        color: #fff;
        grid-column: 1/6;
        grid-row: 1/3;
        text-align: center;
    }
    .sobre h2 {
        font-family: 'Poppins', sans-serif;
        font-weight: 800;
        font-size: 3em;
        padding: 5px;
        margin-top: -30px;
        margin-bottom: 20px;
        text-transform: uppercase;
        text-shadow: 2px 2px 3px black;
    }
    .sobre p {
        font-family: 'Poppins', sans-serif;
        font-weight: 100;
        font-size: 1.1em;
    }
    .Clientes {
        display: flex;
        flex-direction: row;
        overflow: hidden;
        mask-image: linear-gradient(to right, transparent, black 20%, black 80%, transparent);
        -webkit-mask-image: linear-gradient(to right, transparent, black 20%, black 80%, transparent);
      }
      
      .Clientes-wrapper {
        animation: scroll 15s linear infinite;
        white-space: nowrap;
        opacity: 0.5;
        transition: 1s all;
      }
      
      @keyframes scroll {
        0% {
          transform: translateX(0);
        }
        100% {
          transform: translateX(-50%);
        }
      }
      
      .Clientes:hover .Clientes-wrapper {
        animation-play-state: paused;
        opacity: 1;
      }
      
      .Clientes img {
        height: 70px; /* Ajuste a altura conforme necessário */
        margin: -50px 10px;
        transition: filter 0.3s ease-in-out;
      }
      
    #logogirassol {
        height: 250px;
        width: 250px;
        transition: 0.5s all;
        transform: 1s ease-in-out;
    }
    #logogirassol:hover {
        transform: translateY(-5px);
        filter: drop-shadow(-2px -2px 3px rgba(255, 166, 0, 0.572));
    }
    #logoeduv {
        height: 250px;
        width: 250px;
        transition: 0.5s all;
        transform: 1s ease-in-out;
    }
    #logoeduv:hover {
        transform: translateY(-5px);
        filter: drop-shadow(1px 1px 0px rgb(231, 10, 10));
    }
    
    #logocrielacos {
        height: 250px;
        width: 250px;
        transition: 1s all;
        transform: 1s ease-in-out;
    }
    #logocrielacos:hover {
        transform: translateY(-5px);
        filter: drop-shadow(2px 2px 0px rgb(255, 154, 154));
    }
    
    #logomiguel {
        margin-bottom: 10px;
        height: 150px;
        width: 250px;
        transition: 0.5s all;
        transform: 1s ease-in-out;
    }
    #logomiguel:hover {
        transform: translateY(-5px);
        filter: drop-shadow(-2px -2px 10px rgba(41, 125, 148, 1));
    }
    #logosc {
        height: 250px;
        width: 250px;
        transition: 0.5s all;
        transform: 1s ease-in-out;
    }
    #logosc:hover {
        transform: translateY(-5px);
        filter: drop-shadow(-2px -2px 3px rgb(187, 0, 131));
    }
    #logopw {
        margin-bottom: 20px;
        height: 150px;
        width: 150px;
        transition: 0.5s all;
        transform: 1s ease-in-out;
    }
    #logopw:hover {
        transform: translateY(-5px);
        filter: drop-shadow(-2px -2px 1px rgb(237, 19, 19));
    }
    #logoupg {
        height: 250px;
        width: 250px;
        transition: 0.5s all;
        transform: 1s ease-in-out;
    }
    #logoupg:hover {
        transform: translateY(-5px);
        filter: drop-shadow(1px 1px 0px rgba(255, 255, 255, 0.939));
    }
    section {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: repeat(8, 1fr);
        background-color: rgb(0, 0, 0);
        margin: 0 auto;
        width: 99vw;
        height: 55vw;
        text-align: center;
    }
    
    #clique1 {
        font-family: 'Poppins', sans-serif;
        font-weight: 100;
        font-size: 1.1em;
        width: 300px;
        height: 45px;
        background-color: rgba(46, 46, 46, 1);
        color: white;
        border: 1px solid blue;
        cursor: pointer;
        display: inline-block;
        margin-right: 140px;
        margin-left: 150px;
        margin-bottom: -50px;
        border-radius: 30px;
        transition: 0.5s all;
        transform: 0.5s ease;
    }
    #clique1:hover {
        border: 1px solid purple;
        box-shadow: 0 10px 15px rgba(0, 0, 0, 0.534);
        background-color: rgb(0, 0, 0);
    }
}
/*  DESKTOP FULL HD QUERY*/
@media (min-width: 1367px) and (max-width: 1920px) {
    .sobre {
        padding: 50px 220px;
        color: #fff;
        grid-column: 1/6;
        grid-row: 1/3;
    }
    .sobre h2 {
        font-family: 'Poppins', sans-serif;
        font-weight: 800;
        font-size: 4em;
        padding: 5px;
        margin-top: -30px;
        text-transform: uppercase;
        text-shadow: 2px 2px 3px black;
    }
    .sobre p {
        font-family: 'Poppins', sans-serif;
        font-weight: 100;
        font-size: 1.7em;
    }
    .Clientes {
        display: flex;
        flex-direction: row;
        overflow: hidden;
        mask-image: linear-gradient(to right, transparent, black 20%, black 80%, transparent);
        -webkit-mask-image: linear-gradient(to right, transparent, black 20%, black 80%, transparent);
      }
      
      .Clientes-wrapper {
        animation: scroll 15s linear infinite;
        white-space: nowrap;
        opacity: 0.5;
        transition: 1s all;
      }
      
      @keyframes scroll {
        0% {
          transform: translateX(0);
        }
        100% {
          transform: translateX(-50%);
        }
      }
      
      .Clientes:hover .Clientes-wrapper {
        animation-play-state: paused;
        opacity: 1;
      }
      
      .Clientes img {
        height: 100px; /* Ajuste a altura conforme necessário */
        margin: -50px 10px;
        transition: filter 0.3s ease-in-out;
      }
      
    #logogirassol {
        height: 300px;
        width: 300px;
        transition: 0.5s all;
        transform: 1s ease-in-out;
    }
    #logogirassol:hover {
        transform: translateY(-5px);
        filter: drop-shadow(-2px -2px 3px rgba(255, 166, 0, 0.572));
    }
    #logoeduv {
        height: 300px;
        width: 300px;
        transition: 0.5s all;
        transform: 1s ease-in-out;
    }
    #logoeduv:hover {
        transform: translateY(-5px);
        filter: drop-shadow(1px 1px 0px rgb(231, 10, 10));
    }
    
    #logocrielacos {
        height: 300px;
        width: 300px;
        transition: 1s all;
        transform: 1s ease-in-out;
    }
    #logocrielacos:hover {
        transform: translateY(-5px);
        filter: drop-shadow(2px 2px 0px rgb(255, 154, 154));
    }
    
    #logomiguel {
        margin-bottom: 10px;
        height: 200px;
        width: 350px;
        transition: 0.5s all;
        transform: 1s ease-in-out;
    }
    #logomiguel:hover {
        transform: translateY(-5px);
        filter: drop-shadow(-2px -2px 10px rgba(41, 125, 148, 1));
    }
    #logosc {
        height: 300px;
        width: 300px;
        transition: 0.5s all;
        transform: 1s ease-in-out;
    }
    #logosc:hover {
        transform: translateY(-5px);
        filter: drop-shadow(-2px -2px 3px rgb(187, 0, 131));
    }
    #logopw {
        margin-bottom: 20px;
        height: 200px;
        width: 200px;
        transition: 0.5s all;
        transform: 1s ease-in-out;
    }
    #logopw:hover {
        transform: translateY(-5px);
        filter: drop-shadow(-2px -2px 1px rgb(237, 19, 19));
    }
    #logoupg {
        height: 300px;
        width: 300px;
        transition: 0.5s all;
        transform: 1s ease-in-out;
    }
    #logoupg:hover {
        transform: translateY(-5px);
        filter: drop-shadow(1px 1px 0px rgba(255, 255, 255, 0.939));
    }
    section {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: repeat(8, 1fr);
        background-color: rgb(0, 0, 0);
        margin: 0 auto;
        width: 99vw;
        height: 53vw;
        text-align: center;
        padding: 0px 0px 70px 0px;
    }
    
    #clique1 {
        font-family: 'Poppins', sans-serif;
        font-weight: 100;
        font-size: 1.2em;
        width: 350px;
        height: 60px;
        background-color: rgba(46, 46, 46, 1);
        color: white;
        border: 1px solid blue;
        cursor: pointer;
        display: inline-block;
        margin-right: 140px;
        margin-left: 150px;
        margin-bottom: -50px;
        border-radius: 30px;
        transition: 0.5s all;
        transform: 0.5s ease;
    }
    #clique1:hover {
        border: 1px solid purple;
        box-shadow: 0 10px 15px rgba(0, 0, 0, 0.534);
        background-color: rgb(0, 0, 0);
    }
}