.alerta {
  padding: 15px;
  margin-top: 20px;
  border-radius: 5px;
  text-align: center;
  position: fixed;
  background-color: #dff0d8;
  border: 1px solid #d0e9c6;
  bottom: 20px;
  right: 20px;
  z-index: 1000; /* Certifique-se de que o alerta fique acima de outros elementos */
  min-width: 300px;
}

.alerta.sucesso {
  background-color: #dff0d8;
  border: 1px solid #d0e9c6;
  color: #3c763d;
}

.alerta.erro {
  background-color: #f2dede;
  border: 1px solid #ebccd1;
  color: #a94442;
}
