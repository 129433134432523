/*  SMARTPHONE PORTRAIT QUERY*/
@media (min-width: 300px) and (max-width: 768px) {
    #section2 {
        margin-top: 400px;
        background-image: url('../photo-bg.jpeg');
        background-size: cover;
        background-position: -330px;
        width: 100vw;
        padding: 10px;
        height: 155vw;
        display: flex;
        flex-direction: column;
    }
    
    .infos {
        background-color: rgba(0, 0, 0, 0.773);
        border-radius: 30px;
        border-style: solid;
        border-width: 2px;
        border-color: rgb(255, 255, 255);
        color: white;
        padding: 10px;
        margin: 0 auto;
        margin-left: 67px;
        margin-top: 60px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
    }
    .infos h1 {
        font-weight: 800;
        text-transform: uppercase;
        font-size: 14px;
    }
    #logo-icon {
        margin-top: -60px;
        margin-bottom: -30px;
        height: 168px;
        width: 168px;
        filter: drop-shadow(-2px 15px 10px rgba(0, 0, 0, 0.818));
        display: inline-block;
        transition: 1s all;
        transform: 1s ease;
    }
    #logo-icon:hover {
        filter: brightness(0.8);
        transform: translateY(-10px);
        filter: drop-shadow(-2px 15px 10px rgba(0, 0, 0, 0.818));
    }
    #whatsapp-icon {
        height: 38px;
        width: 38px;
        padding: 5px;
        margin-right: 10px;
        margin-left: 20px;
        display: inline-block;
        transition: 1s all;
        transform: 1s ease;
        filter: drop-shadow(-2px 15px 10px rgba(0, 0, 0, 0.818))
    }
    #instagram-icon {
        height: 40px;
        width: 40px;
        padding: 5px;
        margin-right: 10px;
        display: inline-block;
        transition: 1s all;
        transform: 1s ease;
        filter: drop-shadow(-2px 15px 10px rgba(0, 0, 0, 0.818))
    }
    #instagram-icon:hover {
        filter: brightness(0.8);
        transform: translateY(-10px);
        filter: drop-shadow(2px 10px 1px rgba(0, 0, 0, 0.737));
    }
    
    #facebook-icon {
        height: 40px;
        width: 40px;
        padding: 5px;
        margin-right: 10px;
        display: inline-block;
        transition: 1s all;
        transform: 1s ease;
        filter: drop-shadow(-2px 15px 10px rgba(0, 0, 0, 0.818))
    }
    #facebook-icon:hover {
        filter: brightness(0.8);
        transform: translateY(-10px);
        filter: drop-shadow(2px 10px 1px rgba(0, 0, 0, 0.737));
    }
    .whatsapp-icon {
        height: 24px;
        width: 24px;
        transition: 0.5s all;
        transform: 1s ease;
    }
    
    #whatsapp-icon:hover {
        filter: brightness(0.8);
        filter: drop-shadow(2px 10px 1px rgba(0, 0, 0, 0.737));
        transform: translateY(-10px);
    
    }
    .infomap {
        margin: 0 auto;
        margin-top: 20px;
        margin-right: 25px;
        background-color: rgba(0, 0, 0, 0.773);
        width: 90%;
        border-radius: 25px;
        border-style: solid;
        border-width: 2px;
        border-color: rgb(255, 255, 255);
    }
    .infomap a {
        text-decoration: none;
    }
    .infomap h2 {
        color: white;
        margin-top: 5px;
    }
    iframe {
        margin-top: -5px;
        margin-bottom: 20px;
        width: 300px;
        height: 200px;
        border-radius: 30px;
    }
}
/*  SMARTPHONE LANDSCAPE QUERY*/
@media (min-width: 768px) and (max-width: 1099px) {
  
}
/*  DESKTOP HALF HD QUERY*/
@media (min-width: 1100px) and (max-width: 1366px) {
    #section2 {
        background-image: url('../photo-bg.jpeg');
        background-size: cover;
        background-position: -30px -10px;
        width: 100vw;
        padding: 10px;
        height: 25vw;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
    }
    
    .infos {
        color: white;
        grid-column: 1;
        grid-row: 1;
        padding: 10px;
        margin-left: -200px;
        margin-top: 60px;
        font-weight: 600;
        font-family: 'Poppins', sans-serif;
    }
    .infos h1 {
        font-weight: 800;
        text-transform: uppercase;
        font-size: 18px;
    }
    #logo-icon {
        margin-top: -60px;
        margin-bottom: -30px;
        height: 168px;
        width: 168px;
        filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.534));
        display: inline-block;
        transition: 1s all;
        transform: 1s ease;
    }
    #logo-icon:hover {
        filter: brightness(0.8);
        transform: translateY(-10px);
        filter: drop-shadow(-2px 15px 10px rgba(0, 0, 0, 0.818));
    }
    #whatsapp-icon {
        height: 38px;
        width: 38px;
        padding: 5px;
        margin-right: 10px;
        margin-left: 20px;
        display: inline-block;
        transition: 1s all;
        transform: 1s ease;
    }
    #instagram-icon {
        height: 40px;
        width: 40px;
        padding: 5px;
        margin-right: 10px;
        display: inline-block;
        transition: 1s all;
        transform: 1s ease;
    }
    #instagram-icon:hover {
        filter: brightness(0.8);
        transform: translateY(-10px);
        filter: drop-shadow(2px 10px 1px rgba(0, 0, 0, 0.737));
    }
    
    #facebook-icon {
        height: 40px;
        width: 40px;
        padding: 5px;
        margin-right: 10px;
        display: inline-block;
        transition: 1s all;
        transform: 1s ease;
    }
    #facebook-icon:hover {
        filter: brightness(0.8);
        transform: translateY(-10px);
        filter: drop-shadow(2px 10px 1px rgba(0, 0, 0, 0.737));
    }
    .whatsapp-icon {
        height: 24px;
        width: 24px;
        transition: 0.5s all;
        transform: 1s ease;
    }
    
    #whatsapp-icon:hover {
        filter: brightness(0.8);
        filter: drop-shadow(2px 10px 1px rgba(0, 0, 0, 0.737));
        transform: translateY(-10px);
    
    }
    .infomap {
        grid-column: 2;
        grid-row: 1;
        margin-left: 0px;
        margin-top: -15px;
    }
    .infomap a {
        text-decoration: none;
    }
    .infomap h2 {
        color: white;
        font-family: 'Poppins', sans-serif;
    }
    iframe {
        width: 350px;
        height: 250px;
    }
}
/*  DESKTOP FULL HD QUERY*/
@media (min-width: 1367px) and (max-width: 1920px) {
    #section2 {
        background-image: url('../photo-bg.jpeg');
        background-size: cover;
        background-position: -35px -10px;
        font-family: 'Poppins', sans-serif;
        width: 100vw;
        padding: 10px;
        height: 25vw;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
    }
    
    .infos {
        color: white;
        grid-column: 1;
        grid-row: 1;
        padding: 10px;
        margin-top: 120px;
        margin-left: -250px;
        font-weight: 600;
        font-size: 16px;
    }
    .infos h1 {
        font-weight: 800;
        text-transform: uppercase;
        font-size: 22px;
    }
    #logo-icon {
        margin-top: -60px;
        margin-bottom: -30px;
        height: 192px;
        width: 192px;
        filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.534));
        display: inline-block;
        transition: 1s all;
        transform: 1s ease;
    }
    #logo-icon:hover {
        filter: brightness(0.8);
        transform: translateY(-10px);
        filter: drop-shadow(-2px 15px 10px rgba(0, 0, 0, 0.818));
    }
    #whatsapp-icon {
        height: 38px;
        width: 38px;
        padding: 5px;
        margin-right: 10px;
        margin-left: 20px;
        display: inline-block;
        transition: 1s all;
        transform: 1s ease;
    }
    #instagram-icon {
        height: 40px;
        width: 40px;
        padding: 5px;
        margin-right: 10px;
        display: inline-block;
        transition: 1s all;
        transform: 1s ease;
    }
    #instagram-icon:hover {
        filter: brightness(0.8);
        transform: translateY(-10px);
        filter: drop-shadow(2px 10px 1px rgba(0, 0, 0, 0.737));
    }
    
    #facebook-icon {
        height: 40px;
        width: 40px;
        padding: 5px;
        margin-right: 10px;
        display: inline-block;
        transition: 1s all;
        transform: 1s ease;
    }
    #facebook-icon:hover {
        filter: brightness(0.8);
        transform: translateY(-10px);
        filter: drop-shadow(2px 10px 1px rgba(0, 0, 0, 0.737));
    }
    .whatsapp-icon {
        height: 24px;
        width: 24px;
        transition: 0.5s all;
        transform: 1s ease;
    }
    
    #whatsapp-icon:hover {
        filter: brightness(0.8);
        filter: drop-shadow(2px 10px 1px rgba(0, 0, 0, 0.737));
        transform: translateY(-10px);
    
    }
    .infomap {
        grid-column: 2;
        grid-row: 1;
        margin-left: 250px;
        margin-top: 20px;
    }
    .infomap a {
        text-decoration: none;
    }
    .infomap h2 {
        color: white;
    }
    iframe {
        width: 400px;
        height: 300px;
    }
}