/*  SMARTPHONE PORTRAIT QUERY*/
@media (min-width: 300px) and (max-width: 768px) {
    footer {
        position: absolute;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        text-align: center;
        color: white;
        background-color: rgba(0, 0, 0, 0.808);
        margin: 0 auto;
        height: 70px;
        width: 100%;
    }
}
/*  SMARTPHONE LANDSCAPE QUERY*/
@media (min-width: 769px) and (max-width: 1099px) {
    footer {
        position: absolute;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        text-align: center;
        color: white;
        background-color: rgba(0, 0, 0, 0.508);
        padding: 20px 0px;
        margin: 0 auto;
        height: 50px;
        width: 100%;
    }
}
/*  DESKTOP HALF HD QUERY*/
@media (min-width: 1100px) and (max-width: 1366px) {
    footer {
        position: absolute;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        text-align: center;
        color: white;
        background-color: rgba(0, 0, 0, 0.508);
        padding: 20px 0px;
        margin: 0 auto;
        height: 50px;
        width: 100%;
    }
}
/*  DESKTOP FULL HD QUERY*/
@media (min-width: 1367px) and (max-width: 1920px) {
    footer {
        position: absolute;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        text-align: center;
        color: white;
        background-color: rgba(0, 0, 0, 0.508);
        padding: 20px 0px;
        margin: 0 auto;
        height: 50px;
        width: 100%;
    }
}