/*  SMARTPHONE PORTRAIT QUERY*/
@media (min-width: 300px) and (max-width: 768px) {
    #section3 {
        height: 0vw;
    }
    .formulario {
        padding: 0px;
        grid-column: 3;
        grid-row: 2;
        z-index: 2;
    }
    
    form {
        margin: 0 -10px ;
        font-family: 'Poppins', sans-serif;
        background-color: #fff;
        padding: 50px;
        width: 80vw;
        height: 435px;
        border-radius: 8px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
    form h2 {
        text-transform: uppercase;
        font-weight: 800;
        font-size: 22px;
        color: rgb(0, 0, 0);
        text-align: center;
        margin-top: -30px;
        margin-bottom: 10px;
    }
    
    form input, form textarea, form select {
        font-weight: 100;
        color: rgb(0, 0, 0);
        width: calc(100% - 10px);
        text-align: center;
        resize: none;
        padding: 10px;
        margin-bottom: 10px;
        border: 1px solid #000000;
        border-radius: 4px;
        font-size: 1.1em;
    }
    form label {
        font-size: 1em;
    }
    form button {
        width: 100%;
        padding: 10px;
        background-color: rgb(0, 0, 0);
        box-shadow: -2px 5px 5px rgba(0, 0, 0, 0.534);
        color: #fff;
        border: none;
        display: inline-block;
        border-radius: 30px;
        cursor: pointer;
        font-size: 14px;
        transition: 0.6s ease;
    }
}
/*  SMARTPHONE LANDSCAPE QUERY*/
@media (min-width: 768px) and (max-width: 1099px) {
  
}
/*  DESKTOP HALF HD QUERY*/
@media (min-width: 1100px) and (max-width: 1366px) {
    #section3 {
        height: 0vw;
        background-color: linear-gradient( rgba);
    }
    .formulario {
        padding: 0px;
        grid-column: 3;
        grid-row: 2;
        z-index: 2;
    }
    
    form {
        margin-top: -550px;
        font-family: 'Poppins', sans-serif;
        background-color: #fff;
        padding: 40px;
        width: 350px;
        height: 400px;
        border-radius: 8px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
    form h2 {
        text-transform: uppercase;
        font-weight: 800;
        font-size: 22px;
        color: rgb(0, 0, 0);
        text-align: center;
        margin-top: -30px;
        margin-bottom: 10px;
    }
    
    form input, form textarea, form select {
        font-weight: 100;
        color: rgb(31, 31, 31);
        width: calc(100% - 10px);
        text-align: center;
        resize: none;
        padding: 10px;
        margin-bottom: 10px;
        border: 1px solid #ddd;
        border-radius: 4px;
        font-size: 0.8em;
    }
    form label {
        font-size: 0.9em;
    }
    form button {
        width: 100%;
        padding: 5px;
        font-family: 'Poppins', sans-serif;
        background-color: rgba(46, 46, 46, 0.893);
        color: #fff;
        border: none;
        display: inline-block;
        border-radius: 30px;
        cursor: pointer;
        font-size: 14px;
        transition: 0.6s ease;
    }
    form button:hover {
        border: 1px solid blue;
        background-color: rgb(0, 0, 0);
    }
}
/*  DESKTOP FULL HD QUERY*/
@media (min-width: 1367px) and (max-width: 1920px) {
    #section3 {
        height: 0vw;
        background-color: linear-gradient( rgba);
    }
    .formulario {
        padding: 0px;
        grid-column: 3;
        grid-row: 2;
        z-index: 2;
    }
    
    form {
        margin-top: -550px;
        font-family: 'Poppins', sans-serif;
        background-color: #fff;
        padding: 40px;
        width: 550px;
        height: 460px;
        border-radius: 8px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
    form h2 {
        text-transform: uppercase;
        font-weight: 800;
        color: rgb(0, 0, 0);
        text-align: center;
        margin-top: -35px;
        margin-bottom: 20px;
    }
    
    form input, form textarea, form select {
        font-weight: 100;
        color: rgb(31, 31, 31);
        width: calc(100% - 10px);
        text-align: center;
        resize: none;
        padding: 10px;
        margin-bottom: 10px;
        border: 1px solid #ddd;
        border-radius: 4px;
        font-size: 18px;
    }
    form button {
        width: 100%;
        padding: 10px;
        background-color: rgba(46, 46, 46, 0.893);
        color: #fff;
        border: none;
        display: inline-block;
        border-radius: 30px;
        cursor: pointer;
        font-size: 14px;
        transition: 0.6s ease;
    }
    form button:hover {
        border: 1px solid blue;
        box-shadow: -2px 5px 5px rgba(0, 0, 0, 0.534);
        background-color: rgb(0, 0, 0);
    }
}