/*  SMARTPHONE PORTRAIT QUERY*/
@media (min-width: 300px) and (max-width: 768px) {
    .servicos {
        height: 100%;
        display: flex;
        flex-direction: column;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(4, 1fr);
        justify-content: space-evenly;
        padding: 10px 10px;
        background-color: rgb(0, 0, 0);
    }
    .servicos h2 {
        text-transform: uppercase;
        font-weight: 800;
        font-family: 'Poppins', sans-serif;
        font-size: 50px;
        padding: 35px 30px;
        border-bottom: 2px solid rgb(165, 165, 165);
        margin: 0 auto;
        margin-bottom: 60px;
        margin-top: -80px;
        color: white;
    }
    .card1, .card2, .card3 {
        height: 55vw;
        width: 100vw;
        margin: 0 auto;
        margin-left: -10px;
        margin-top: 30px;
        margin-bottom: 30px;
        border-radius: 20px;
        background-color: rgb(0, 0, 0);
        cursor: pointer;
        display: inline-block;
        transition: 1s all;
        transform: 0.3s ease;
        box-shadow: -3px 3px 3px rgb(0, 0, 0);
        filter: brightness(0.9);
    }
    .card1 a, .card2 a, .card3 a {
        text-decoration: none;
    }
    .card1 h1, .card2 h1, .card3 h1 {
        margin-top: -45px;
        margin-bottom: 30px;
        font-family: 'Poppins', sans-serif;
        text-transform: uppercase;
        font-size: 25px;
        font-weight: 800;
        color: rgb(255, 255, 255);
        text-shadow: 5px 2px 5px black;
    }
    .card1 h3, .card2 h3, .card3 h3 {
        margin-top: 10px;
        margin-left: 223px;
        font-family: 'Poppins', sans-serif;
        border-radius: 15px;
        border: 1px solid blue ;
        font-weight: 400;
        font-size: 14px;
        color: rgb(255, 255, 255);
        text-shadow: 1px 1px 1px rgb(0, 0, 0);
        background-color: rgb(12, 12, 12);
        padding: 7px 0px;
        max-width: 39%;
        transition: 1s all;
        transform: 1s ease-in-out;
    }
    .card2 h3 {
        margin-top: 15px;
        margin-bottom: -5px;
        margin-left: 15px;
    }
    .card3 h3 {
        margin-top: 10px;
    }

    .card1 h3:hover, .card2 h3:hover, .card3 h3:hover {
        transform: translateY(-2px);
        color: rgb(0, 0, 0);
        background-color: rgb(255, 255, 255);
        box-shadow: -3px 3px 3px rgba(0, 0, 0, 0.727);
    }
    .card1 {
        background-image: url(https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?auto=format&fit=crop&w=400&h=240);
        background-size: contain;
        background-position: -180px;
        background-repeat: no-repeat;
    }
    .card1:hover, .card2:hover, .card3:hover {
        filter: brightness(1);
    }
    .card2 {
        grid-column: 2;
        background-image: url(https://images.unsplash.com/photo-1560472354-b33ff0c44a43?auto=format&fit=crop&w=400&h=240);
        background-size: contain;
        background-position: 200px;
        background-repeat: no-repeat;
    }
    .card3 {
        grid-column: 3;
        background-image: url(https://th.bing.com/th/id/R.e677d78d7662d666f698d0661b780c78?rik=SeNj8IM%2fCnuLDg&pid=ImgRaw&r=0);
        background-size: 85vw;
        background-position: -150px;
        background-repeat: no-repeat;
    }
    .item1 {
        margin-left: -20px;
        margin-bottom: 20px;
        grid-column: 2; /* Coluna 2 */
        grid-row: 8; /* Linha 2 */
    }
    #clique2 {
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 1.1em;
        text-transform: uppercase;
        margin-left: 23px;
        width: 380px;
        height: 50px;
        background-color: rgb(255, 255, 255);
        color: rgb(0, 0, 0);
        cursor: pointer;
        border-radius: 30px;
        transition: 0.5s all;
        transform: 1.5s ease-in-out;
    }
    #clique2:hover {
        transform: translateY(-5px);
        color: white;
        box-shadow: 0 10px 15px rgba(0, 0, 0, 0.534);
        border-color: blue;
        background-color: rgba(14, 14, 14, 0.893);
    }
}
/*  SMARTPHONE LANDSCAPE QUERY*/
@media (min-width: 768px) and (max-width: 1099px) {
  
}
/*  DESKTOP HALF HD QUERY*/
@media (min-width: 1100px) and (max-width: 1366px) {
    .servicos {
        height: 38vw;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(4, 1fr);
        justify-content: space-evenly;
        padding: 10px 10px;
        background-color: rgb(0, 0, 0);
    }
    .servicos h2 {
        grid-column: 2;
        text-transform: uppercase;
        font-weight: 800;
        font-size: 50px;
        margin-bottom: 40px;
        margin-top: 10px;
        color: white;
    }
    .card1, .card2, .card3 {
        grid-row: 2;
        height: 250px;
        width: 380px;
        margin: 25px 30px 25px 30px;
        background-color: blue;
        border-radius: 15px;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.53);
        cursor: pointer;
        display: inline-block;
        transition: 1s all;
        transform: 0.3s ease;
        filter: brightness(0.7);
    }
    .card1 a, .card2 a, .card3 a {
        text-decoration: none;
    }
    .card1 h1, .card2 h1, .card3 h1 {
        margin-top: -50px;
        font-family: 'Segoe UI', sans-serif;
        text-transform: uppercase;
        font-size: 28px;
        font-weight: 400;
        color: rgb(255, 255, 255);
        text-shadow: 5px 2px 5px black;
    }
    .card1 h3, .card2 h3, .card3 h3 {
        margin-top: 28px;
        margin-left: 90px;
        font-family: 'Poppins', sans-serif;
        border-radius: 10px;
        font-weight: 400;
        font-size: 14px;
        color: rgb(0, 0, 0);
        text-shadow: 0px 0px 0px rgb(0, 0, 0);
        background-color: rgba(255, 255, 255, 0.749);
        padding: 5px;
        max-width: 50%;
        transition: 0.5s all;
        transform: 0.3s ease;
        box-shadow: 5px 2px 10px rgb(0, 0, 0);
    }
    .card2 h3 {
        margin-top: 28px;
    }
    .card1 h3:hover, .card2 h3:hover, .card3 h3:hover {
        transform: translateY(-7px);
        color: white;
        background-color: rgba(0, 0, 0, 0.92);
        box-shadow: 5px 10px 3px rgb(0, 0, 0);
        border: 1px solid blue;
    }
    .card1 {
        background-image: url(https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?auto=format&fit=crop&w=400&h=240);
        background-size: cover;
    }
    .card1:hover, .card2:hover, .card3:hover {
        box-shadow: 0px 0px 100px rgba(58, 58, 58, 0.534);
        border: 1px solid white;
        filter: brightness(1);
    }
    .card2 {
        grid-column: 2;
        background-image: url(https://images.unsplash.com/photo-1560472354-b33ff0c44a43?auto=format&fit=crop&w=400&h=240);
        background-size: 75vh;
    }
    .card3 {
        grid-column: 3;
        background-image: url(https://th.bing.com/th/id/R.e677d78d7662d666f698d0661b780c78?rik=SeNj8IM%2fCnuLDg&pid=ImgRaw&r=0);
        background-size: cover;
    }
    .item1 {
        margin-left: -20px;
        margin-bottom: 20px;
        grid-column: 2; /* Coluna 2 */
        grid-row: 8; /* Linha 2 */
    }
    #clique2 {
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 1.1em;
        text-transform: uppercase;
        margin-left: 35px;
        width: 380px;
        height: 50px;
        background-color: rgb(255, 255, 255);
        color: rgb(0, 0, 0);
        cursor: pointer;
        border-radius: 30px;
        transition: 0.5s all;
        transform: 1.5s ease-in-out;
    }
    #clique2:hover {
        transform: translateY(-5px);
        color: white;
        box-shadow: 0 10px 15px rgba(0, 0, 0, 0.534);
        border-color: blue;
        background-color: rgba(14, 14, 14, 0.893);
    }
}
/*  DESKTOP HALF HD QUERY*/
@media (min-width: 1367px) and (max-width: 1920px) {
    .servicos {
        height: 33vw;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(4, 1fr);
        justify-content: space-evenly;
        padding: 10px 10px;
        background-color: rgb(0, 0, 0);
    }
    .servicos h2 {
        grid-column: 2;
        text-transform: uppercase;
        font-weight: 800;
        font-size: 70px;
        margin-top: 0px;
        margin-bottom: 100px;
        color: white;
    }
    .card1, .card2, .card3 {
        grid-row: 2;
        height: 275px;
        width: 550px;
        margin: 0px 30px;
        background-color: blue;
        border-radius: 30px;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.53);
        cursor: pointer;
        display: inline-block;
        transition: 0.5s all;
        transform: 1s ease-in;
        z-index: 0;
        filter: brightness(0.8);
    }
    .card1 a, .card2 a, .card3 a {
        text-decoration: none;
    }
    .card1 h1, .card2 h1, .card3 h1 {
        margin-top: -50px;
        font-family: 'Poppins', sans-serif;
        text-transform: uppercase;
        font-weight: 600;
        color: rgb(255, 255, 255);
        text-shadow: 5px 2px 5px black;
    }
    .card1 h3, .card2 h3, .card3 h3 {
        margin-top: 25px;
        margin-left: 145px;
        font-family: 'Poppins', sans-serif;
        border-radius: 15px;
        font-weight: 400;
        font-size: 18px;
        color: rgb(0, 0, 0);
        background-color: rgba(255, 255, 255, 0.749);
        padding: 5px;
        max-width: 45%;
        transition: 0.3s all;
        transform: 0.3s ease;
        box-shadow: 5px 2px 10px rgb(0, 0, 0);
        filter: brightness(1);
        z-index: 1;
    }
    .card2 h3 {
        margin-top: 25px;
    }
    .card1 h3:hover, .card2 h3:hover, .card3 h3:hover {
        transform: translateY(-7px);
        color: white;
        background-color: rgba(0, 0, 0, 0.92);
        box-shadow: 5px 10px 3px rgb(0, 0, 0);
        border: 1px solid blue;
    }
    .card1 {
        background-image: url(https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?auto=format&fit=crop&w=400&h=240);
        background-size: cover;
        background-position: 0px -50px;
    }
    .card1:hover, .card2:hover, .card3:hover {
        box-shadow: 0px 0px 100px rgba(58, 58, 58, 0.534);
        border: 1px solid white;
        filter: brightness(1);
    }
    .card2 {
        grid-column: 2;
        background-image: url(https://images.unsplash.com/photo-1560472354-b33ff0c44a43?auto=format&fit=crop&w=400&h=240);
        background-size: 75vh;
    }
    .card3 {
        grid-column: 3;
        background-image: url(https://th.bing.com/th/id/R.e677d78d7662d666f698d0661b780c78?rik=SeNj8IM%2fCnuLDg&pid=ImgRaw&r=0);
        background-size: cover;
    }
    .item1 {
        margin-left: -20px;
        margin-bottom: 20px;
        grid-column: 2; /* Coluna 2 */
        grid-row: 8; /* Linha 2 */
    }
    #clique2 {
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 1.2em;
        text-transform: uppercase;
        margin-left: 35px;
        width: 400px;
        height: 50px;
        background-color: rgb(255, 255, 255);
        color: rgb(0, 0, 0);
        cursor: pointer;
        border-radius: 30px;
        transition: 0.5s all;
        transform: 1.5s ease-in-out;
    }
    #clique2:hover {
        transform: translateY(-5px);
        color: white;
        box-shadow: 0 10px 15px rgba(0, 0, 0, 0.534);
        border-color: blue;
        background-color: rgba(14, 14, 14, 0.893);
    }
}